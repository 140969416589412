.portfolio-header {
    display: flex;
    width: 100%;
    margin-top: -6%;
}

.portfolio-header img {
    display: flex;
    align-self: center;
    width: 100%;
    margin: auto;
    margin-top: 7%;
}

.portfolio-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 5%;
}

.portfolio-main img{
    width: 50%;
}