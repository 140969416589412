.privacy-banner {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 10%;
    margin-bottom: 10%;
    
}

.privacy-heading h1 {
    display: flex;
    justify-content: center;
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 5%;
    background: linear-gradient(270deg, #048985 25.83%, #35CDC8 97.85%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.privacy-banner h3 {
    font-weight: 600;
    background: linear-gradient(270deg, #048985 25.83%, #35CDC8 97.85%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.privacy-banner p {
    color: #000;
    width: fit-content;
    /* body */
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.privacy-bannera,
.privacy-banner a:hover {
    width: fit-content;
    text-decoration: none !important;
}

.privacy-banner h2 {
    font-size: 26px;
}

.privacy-banner a p{
    font-weight: 600;
}
.privacy-banner a {
    text-decoration: none;
    background: linear-gradient(270deg, #048985 25.83%, #35CDC8 97.85%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


@media (max-width:1366px) {
    .privacy-banner::before {
        height: 575px;
        width: 575px
    }

}

@media (max-width:920px) {
    .privacy-banner {
        margin-top: 30%;
    }
}

@media (max-width: 650px) {
    .privacy-banner::before {
        top: -396px;
        left: -306px;
    }

    .privacy-banner {
        margin-top: 30%;
    }
}

@media (max-width:640px) {
    .privacy-banner {
        margin-top: 30%;
    }
}

@media (max-width: 420px) {
    .privacy-banner {
        margin-top: 55%;
    }
}

@media (max-width: 375px) {
    .privacy-banner {
        margin-top: 55%;
    }
}

@media (max-width: 360px) {
    .privacy-banner {
        margin-top: 55%;
    }
}

@media (max-width: 300px) {
    .privacy-banner {
        margin-top: 80%;
    }

}